<template>
  <section>
      <header class="mb-2">
          <b-card>
              <b-row>
                  <b-col cols="12">
                      <section class="d-flex justify-content-between">
                          <div>
                              <p><strong>No. Invoice</strong></p>
                              <p>{{ pb.no_invoice }}</p>
                          </div>
                          <div v-if="pb.selesai == 0">
                              <b-button @click.prevent="$router.push({name: 'penyimpanan', query: {inv: pb.id}})" variant="primary">
                                  <span class="align-middle">Tempatkan Barang</span>
                                  <feather-icon icon="ChevronRightIcon" class="ml-1">
                                  </feather-icon>
                              </b-button>
                          </div>
                      </section>
                  </b-col>
                  <b-col md="3" sm="12">
                      <p><strong>Tanggal Penerimaan</strong></p>
                      <p>{{ humanDate(pb.tanggal_penerimaan) }}</p>
                  </b-col>
                  <b-col md="3" sm="12">
                      <p><strong>Tanggal Invoice</strong></p>
                      <p>{{ humanDate(pb.tanggal_invoice) }}</p>
                  </b-col>
                  <b-col md="2" sm="12">
                      <p><strong>Status</strong></p>
                      <p v-if="pb.selesai == 1"> <b-badge variant="success">Selesai</b-badge></p>
                      <p v-if="pb.selesai == 0">Belum ditempatkan</p>
                  </b-col>
                  <b-col md="4" sm="12">
                      <p><strong>Keterangan</strong></p>
                      <p>{{pb.keterangan ? pb.keterangan : 'kosong'}}</p>
                  </b-col>
              </b-row>
          </b-card>
      </header>

      <main>
          <b-card>
              <h5>Daftar Barang Yang Diterima</h5>
              <hr />
        <b-col sm="12" md="12" xl="12" class="d-flex justify-content-end">
          <h2 class="text-primary text-right">
            Total: <strong>Rp {{ formatRupiah(sum) }}</strong>
          </h2>
        </b-col>

              <b-table striped hover :items="barangs" :fields="barangFields">
                  <template #cell(no)="row">
                      {{ (row.index + 1) }}
                  </template>
                  <template #cell(barang)="{item}">
                      {{ item.barang ? item.barang.nama : '-' }}
                  </template>
                  <template #cell(satuan)="{item}">
                      {{ item.barang && item.barang.satuan ? item.barang.satuan.satuan : '-' }}
                  </template>
                  <template #cell(jumlah)="{item}">
                    {{ formatRupiah(item.jumlah) }}
                  </template>
                  <template #cell(total)="{ item }">
                      {{ formatRupiah(item.harga * item.jumlah) }}
                  </template>
                  <template #cell(harga_dasar)="{ item }">
                      {{ formatRupiah(item.barang ? item.barang.harga_dasar : '-') }}
                  </template>
                  <template #cell(harga)="{ item }">
                      {{ formatRupiah(item.harga) }}
                  </template>
              </b-table>
          </b-card>
      </main>
  </section>
</template>
<script>
  import {BRow, BCol, BCard,
  BBadge, BButton, BTable} from 'bootstrap-vue'
  export default {
      components: {
          BRow,
          BCol,
          BButton,
          BCard,
  BBadge,
          BTable
      },
      data: () => ({
          pb: {
              no_invoice: null,
              tanggal_invoice: null,
              tanggal_penerimaan: null,
              keterangan: null,
              selesai: null
          },
          barangFields: [
              {key: 'no', label: 'No', sortable: true},
            //   {key: 'no_container', label: 'No. Container', sortable: true},
            //   {key: 'no_seal', label: 'No. Seal', sortable: true},
              {key: 'barang', label: 'Nama Barang', sortable: true},
            //   {key: 'jumlah', label: 'Jumlah', sortable: true},
        { key: "harga_dasar", label: "Harga Dasar", sortable: true },
        { key: "harga", label: "Harga Beli", sortable: true },
        { key: "jumlah", label: "Kuantitas", sortable: true },
        { key: "satuan", label: "Satuan", sortable: true },
        { key: "total", label: "total", sortable: true },
        { key: "no_container", label: "No. Batch", sortable: true },
              {key: 'satuan', label: 'Satuan', sortable: true}
          ],
          barangs: []
      }),
  computed: {
    sum() {
      if (!this.barangs || this.barangs.length < 1) {
        return 0;
      }

      return this.barangs.reduce((total, item) => (total += parseInt(item.harga * item.jumlah)), 0);
    },
  },
      methods: {
          async getPb() {
              const pb = await this.$store.dispatch('penerimaan-barang/getDataById', this.$route.params.id)
              this.pb = pb
          },
          async getBarangPb() {
              const barangs = await this.$store.dispatch('penerimaan-barang/getDataBarang', {
                  penerimaan_id: this.$route.params.id
              })
              this.barangs = barangs
          },
      },
      async created() {
          this.getPb()
          this.getBarangPb()
      }
  }
</script>